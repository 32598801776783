import types from "../types/payments";
const initialState = {
  isStripeProducts: false,
  stripeProducts: [],
  isStripeProductsError: false,
  isStripeProductsErrorMsg: "",

  isGetUserSubscriptionStatus: false,

  isAddCardToStripe: false,
  isAddCardToStripeError: false,
  isAddCardToStripeErrorMsg: "",

  isRetrieveSubscription: false,
  isRetrieveSubscriptionError: false,
  isRetrieveSubscriptionErrorMsg: "",
  activeSubscription: null,

  isDeletingSubscription: false,
  isDeletingSubscriptionError: false,
  isDeletingSubscriptionerrorMsg: "",

  isUserSavedCards: false,
  isUserSavedCardsErrorMsg: "",
  isUserSavedCardsError: false,
  userSavedCards: [],
  userDefaultCard: null,

  isSetUserDefaultCard: false,
  isSetUserDefaultCardErrorMsg: "",
  isSetUserDefaultCardError: false,

  // REMOVE_USER_CARD
  isRemovingUserCard: false,
  isRemovingUserCardError: false,
  isRemovingUserCardErrorMsg: "",

  // ADD_USER_CARD
  isAddingUserCard: false,
  isAddingUserCardError: false,
  isAddingUserCardErrorMsg: "",
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    //
    // GET_STRIPE_PRODUCTS
    //
    case types.GET_STRIPE_PRODUCTS_REQUEST:
      return {
        ...state,
        isStripeProducts: true,
      };
    case types.GET_STRIPE_PRODUCTS_SUCCESS:
      return {
        ...state,
        isStripeProducts: false,
        stripeProducts: action.payload,
      };

    case types.GET_STRIPE_PRODUCTS_ERROR:
      return {
        ...state,
        isStripeProducts: false,
        isStripeProductsError: true,
        isStripeProductsErrorMsg: action.payload,
      };

    //
    // GET_USER_SUBSCRIPTION_STATUS
    //
    case types.GET_USER_SUBSCRIPTION_STATUS_REQUEST:
      return {
        ...state,
        isGetUserSubscriptionStatus: true,
      };
    case types.GET_USER_SUBSCRIPTION_STATUS_SUCCESS:
      return {
        ...state,
        isGetUserSubscriptionStatus: false,
      };

    case types.GET_USER_SUBSCRIPTION_STATUS_ERROR:
      return {
        ...state,
        isGetUserSubscriptionStatus: false,
      };

    //
    // ADD_CARD_TO_STRIPE
    //
    case types.ADD_CARD_TO_STRIPE_REQUEST:
      return {
        ...state,
        isAddCardToStripe: true,
        isAddCardToStripeErrorMsg: "",
        isAddCardToStripeError: false,
      };
    case types.ADD_CARD_TO_STRIPE_SUCCESS:
      return {
        ...state,
        isAddCardToStripe: false,
      };
    case types.ADD_CARD_TO_STRIPE_ERROR:
      return {
        ...state,
        isAddCardToStripe: false,
        isAddCardToStripeError: true,
        isAddCardToStripeErrorMsg: action.payload,
      };

    //
    // RETRIEVE_USER_SUBSCRIPTION
    //
    case types.RETRIEVE_USER_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isRetrieveSubscription: true,
        isRetrieveSubscriptionErrorMsg: "",
        isRetrieveSubscriptionError: false,
      };
    case types.RETRIEVE_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isRetrieveSubscription: false,
        activeSubscription: action.payload,
      };
    case types.RETRIEVE_USER_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isRetrieveSubscription: false,
        isRetrieveSubscriptionError: true,
        isRetrieveSubscriptionErrorMsg: action.payload,
      };

    //
    // GET_USER_SAVED_CARDS
    //
    case types.GET_USER_SAVED_CARDS_REQUEST:
      return {
        ...state,
        isUserSavedCards: true,
        isUserSavedCardsErrorMsg: "",
        isUserSavedCardsError: false,
      };
    case types.GET_USER_SAVED_CARDS_SUCCESS:
      return {
        ...state,
        isUserSavedCards: false,
        userSavedCards: action.payload.data,
        userDefaultCard: action.payload.default_source,
      };
    case types.GET_USER_SAVED_CARDS_ERROR:
      return {
        ...state,
        isUserSavedCards: false,
        isUserSavedCardsError: true,
        isUserSavedCardsErrorMsg: action.payload,
        userSavedCards: [],
        userDefaultCard: null,
      };

    //
    // DELETE_USER_SUBSCRIPTION
    //
    case types.DELETE_USER_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isDeletingSubscription: true,
        isDeletingSubscriptionErrorMsg: "",
        isDeletingSubscriptionError: false,
      };
    case types.DELETE_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isDeletingSubscription: false,
      };
    case types.DELETE_USER_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isDeletingSubscription: false,
        isDeletingSubscriptionError: true,
        isDeletingSubscriptionErrorMsg: action.payload,
      };

    //
    // SET_USER_DEFAULT_CARD
    //
    case types.SET_USER_DEFAULT_CARD_REQUEST:
      return {
        ...state,
        isSetUserDefaultCard: true,
        isSetUserDefaultCardErrorMsg: "",
        isSetUserDefaultCardError: false,
      };
    case types.SET_USER_DEFAULT_CARD_SUCCESS:
      return {
        ...state,
        isSetUserDefaultCard: false,
        userDefaultCard: action.payload,
      };
    case types.SET_USER_DEFAULT_CARD_ERROR:
      return {
        ...state,
        isSetUserDefaultCard: false,
        isSetUserDefaultCardError: true,
        isSetUserDefaultCardErrorMsg: action.payload,
      };

    //
    // REMOVE_USER_CARD
    //
    case types.REMOVE_USER_CARD_REQUEST:
      return {
        ...state,
        isRemovingUserCard: true,
        isRemovingUserCardError: false,
        isRemovingUserCardErrorMsg: "",
      };
    case types.REMOVE_USER_CARD_SUCCESS:
      let oldCards = state.userSavedCards;
      let newCards = oldCards.filter((i) => i.id !== action.payload.id);
      return {
        ...state,
        isRemovingUserCard: false,
        userSavedCards: newCards,
        userDefaultCard: action.payload.default_source
      };
    case types.REMOVE_USER_CARD_ERROR:
      return {
        ...state,
        isRemovingUserCard: false,
        isRemovingUserCardError: true,
        isRemovingUserCardErrorMsg: action.payload,
      };

    //
    // ADD_USER_CARD
    //
    case types.ADD_USER_CARD_REQUEST:
      return {
        ...state,
        isAddingUserCard: true,
        isAddingUserCardError: false,
        isAddingUserCardErrorMsg: "",
      };
    case types.ADD_USER_CARD_SUCCESS:
      return {
        ...state,
        isAddingUserCard: false,
        userSavedCards: [action.payload, ...state.userSavedCards],
        isAddingUserCardError: false,
        isAddingUserCardErrorMsg: "",
      };
    case types.ADD_USER_CARD_ERROR:
      return {
        ...state,
        isAddingUserCard: false,
        isAddingUserCardError: true,
        isAddingUserCardErrorMsg: action.payload,
      };
    default:
      return state;
  }
};
