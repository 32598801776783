import types from "../types/profile";

const initialState = {
  //
  isUploadingMedia: false,
  uploadingMediaError: false,
  userMedia: [],
  isDeletingMedia: false,
  deletingMediaError: false,
  isGetUserMedia: false,
  isGetUserMediaPagination: false,
  moreUserUploads: true,
  userUploadsPage: 1,
  //
  isSavingUserDesign: false,
  userDesigns: [],
  userDesignsCount: 0,
  userDesignPage: 1,
  designSearch: "",
  isGetUserDesigns: false,
  isGetUserDesignsMore: false,
  isGetUserDesignsError: false,
  isGetUserDesignsErrorMsg: "",
  //
  userDesignsAll: [],
  userDesignsAllCount: 0,
  userDesignsAllPage: 1,
  isGetUserDesignsAll: false,
  isGetUserDesignsAllMore: false,
  isGetUserDesignsAllError: false,
  isGetUserDesignsAllErrorMsg: "",
  //
  isDeletingDesign: false,
  //
  selectedUploadsTab: "image",
  //
  isGetUserFriends: false,
  userFriends: [],
  isGetUserFriendsError: false,
  isGetUserFriendsMsg: "",
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    //
    // UPLOAD MEDIA
    //
    case types.UPLOAD_MEDIA_REQUEST:
      return {
        ...state,
        isUploadingMedia: true,
      };
    case types.UPLOAD_MEDIA_SUCCESS:
      return {
        ...state,
        isUploadingMedia: false,
        // userMedia: [new_obj, ...state.userMedia],
      };

    case types.UPLOAD_MEDIA_ERROR:
      return {
        ...state,
        isUploadingMedia: false,
      };

    //
    // GET USER MEDIA
    //
    case types.GET_USER_MEDIA_REQUEST:
      return {
        ...state,
        isGetUserMedia: true,
        getUserMediaError: false,
        selectedUploadsTab: action.payload.type,
        userUploadsPage: action.payload.page_id,
        moreUserUploads: true,
      };
    case types.GET_USER_MEDIA_SUCCESS: {
      return {
        ...state,
        isGetUserMedia: false,
        userMedia: action.payload,
        moreUserUploads: action.payload.length ? true : false,
      };
    }

    case types.GET_USER_MEDIA_ERROR:
      return {
        ...state,
        isGetUserMedia: false,
        getUserMediaError: true,
      };
    //
    // GET USER MEDIA
    //
    case types.GET_USER_MEDIA_PAGINATION_REQUEST:
      return {
        ...state,
        isGetUserMediaPagination: true,
        getUserMediaError: false,
        userUploadsPage: action.payload.page_id,
      };
    case types.GET_USER_MEDIA_PAGINATION_SUCCESS: {
      return {
        ...state,
        isGetUserMediaPagination: false,
        userMedia: [...state.userMedia, ...action.payload],
        moreUserUploads: action.payload.length ? true : false,
      };
    }
    case types.GET_USER_MEDIA_PAGINATION_ERROR:
      return {
        ...state,
        isGetUserMediaPagination: false,
        getUserMediaError: true,
      };
    //
    // DELETE_USER_MEDIA
    //
    case types.DELETE_USER_MEDIA_REQUEST:
      return {
        ...state,
        isDeletingMedia: true,
        deletingMediaError: false,
      };
    case types.DELETE_USER_MEDIA_SUCCESS: {
      let { id } = action.payload;
      let old = state.userMedia;
      let deletedIndex = old.findIndex((i) => i.id === id);
      if (deletedIndex !== -1) {
        old.splice(deletedIndex, 1);
      }
      return {
        ...state,
        isDeletingMedia: false,
        userMedia: [...old],
      };
    }

    case types.DELETE_USER_MEDIA_ERROR:
      return {
        ...state,
        isDeletingMedia: false,
        deletingMediaError: true,
      };

    //
    // GET_USER_DESIGNS
    //
    case types.GET_USER_DESIGNS_REQUEST:
      return {
        ...state,
        isGetUserDesigns: true,
        isGetUserDesignsError: false,
        userDesignPage: 1,
      };
    case types.GET_USER_DESIGNS_SUCCESS: {
      return {
        ...state,
        isGetUserDesigns: false,
        userDesigns: action.payload.data,
        userDesignsCount: action.payload.total_records,
        designSearch: action.payload.search,
      };
    }

    case types.GET_USER_DESIGNS_ERROR:
      return {
        ...state,
        isGetUserDesigns: false,
        userDesigns: [],
        isGetUserDesignsError: true,
        isGetUserDesignsErrorMsg: action.payload,
        userDesignPage: 1,
      };
    //
    // GET_USER_DESIGNS_MORE
    //
    case types.GET_USER_DESIGNS_MORE_REQUEST:
      return {
        ...state,
        isGetUserDesignsMore: true,
      };
    case types.GET_USER_DESIGNS_MORE_SUCCESS: {
      return {
        ...state,
        isGetUserDesignsMore: false,
        userDesigns: [...state.userDesigns, ...action.payload.data],
        userDesignPage: action.payload.page_id,
        designSearch: action.payload.search,
        userDesignsCount: action.payload.total_records,
      };
    }

    case types.GET_USER_DESIGNS_MORE_ERROR:
      return {
        ...state,
        isGetUserDesignsMore: false,
        userDesigns: [],
        isGetUserDesignsError: true,
        isGetUserDesignsErrorMsg: action.payload,
      };

    //
    // GET_USER_DESIGNS_ALL
    //
    case types.GET_USER_DESIGNS_ALL_REQUEST:
      return {
        ...state,
        isGetUserDesignsAll: true,
        isGetUserDesignsAllError: false,
        userDesignsAllPage: 1,
      };
    case types.GET_USER_DESIGNS_ALL_SUCCESS: {
      return {
        ...state,
        isGetUserDesignsAll: false,
        userDesignsAll: action.payload.data,
        userDesignsAllCount: action.payload.total_records,
      };
    }

    case types.GET_USER_DESIGNS_ALL_ERROR:
      return {
        ...state,
        isGetUserDesignsAll: false,
        userDesignsAll: [],
        isGetUserDesignsAllError: true,
        isGetUserDesignsAllErrorMsg: action.payload,
        userDesignsAllPage: 1,
      };
    //
    // GET_USER_DESIGNS_ALL_MORE
    //
    case types.GET_USER_DESIGNS_ALL_MORE_REQUEST:
      return {
        ...state,
        isGetUserDesignsAllMore: true,
      };
    case types.GET_USER_DESIGNS_ALL_MORE_SUCCESS:
      return {
        ...state,
        isGetUserDesignsAllMore: false,
        userDesignsAll: [...state.userDesignsAll, ...action.payload.data],
        userDesignsAllPage: action.payload.page_id,
        userDesignsAllCount: action.payload.total_records,
      };

    case types.GET_USER_DESIGNS_ALL_MORE_ERROR:
      return {
        ...state,
        isGetUserDesignsAllMore: false,
        isGetUserDesignsAllError: true,
        isGetUserDesignsAllErrorMsg: action.payload,
      };

    //
    // SAVE_USER_DESIGN
    //
    case types.SAVE_USER_DESIGN_REQUEST:
      return {
        ...state,
        isSavingUserDesign: true,
      };
    case types.SAVE_USER_DESIGN_SUCCESS:
      return {
        ...state,
        isSavingUserDesign: false,
        userDesigns: [action.payload, ...state.userDesigns],
        userDesignsAll: [action.payload, ...state.userDesignsAll],
      };
    case types.SAVE_USER_DESIGN_ERROR:
      return {
        ...state,
        isSavingUserDesign: false,
      };
    //
    // SAVE_EXISTING_USER_DESIGN_SUCCESS
    //
    case types.SAVE_EXISTING_USER_DESIGN_SUCCESS: {
      let oldUserDesigns = state.userDesigns;
      let index = oldUserDesigns.findIndex((i) => action.payload.id === i.id);
      if (index > -1) {
        oldUserDesigns.splice(index, 1, { ...action.payload });
        // oldUserDesigns.splice(index, 1,);
      }
      let oldUserDesignsAll = state.userDesignsAll;
      let indexAll = oldUserDesignsAll.findIndex(
        (i) => action.payload.id === i.id
      );
      if (indexAll > -1) {
        // oldUserDesignsAll.splice(index, 1,);
        oldUserDesignsAll.splice(indexAll, 1);
      }

      return {
        ...state,
        isSavingUserDesign: false,
        userDesigns: oldUserDesigns,
        userDesignsAll: [action.payload, ...oldUserDesignsAll],
      };
    }
    //
    // DELETE_USER_DESIGN
    //
    case types.DELETE_USER_DESIGN_REQUEST:
      return {
        ...state,
        isDeletingDesign: true,
      };
    case types.DELETE_USER_DESIGN_SUCCESS: {
      let oldUserDesigns = state.userDesigns;
      let oldUserDesignsAll = state.userDesignsAll;
      let deletedIndex = oldUserDesigns.findIndex(
        (i) => i.id === action.payload
      );
      if (deletedIndex !== -1) {
        oldUserDesigns.splice(deletedIndex, 1);
      }
      let deletedAllIndex = oldUserDesignsAll.findIndex(
        (i) => i.id === action.payload
      );
      if (deletedAllIndex !== -1) {
        oldUserDesignsAll.splice(deletedAllIndex, 1);
      }
      return {
        ...state,
        isDeletingDesign: false,
        userDesigns: [...oldUserDesigns],
        userDesignsAll: [...oldUserDesignsAll],
        userDesignsCount:
          deletedIndex !== -1
            ? state.userDesignsCount - 1
            : state.userDesignsCount,
        userDesignsAllCount:
          deletedAllIndex !== -1
            ? state.userDesignsAllCount - 1
            : state.userDesignsAllCount,
      };
    }
    case types.DELETE_USER_DESIGN_ERROR:
      return {
        ...state,
        isDeletingDesign: false,
      };

    //
    // GET_USER_FRIENDS
    //
    case types.GET_USER_FRIENDS_REQUEST:
      return {
        ...state,
        isGetUserFriends: true,
      };
    case types.GET_USER_FRIENDS_SUCCESS: {
      return {
        ...state,
        isGetUserFriends: false,
        userFriends: action.payload,
      };
    }
    case types.GET_USER_FRIENDS_ERROR:
      return {
        ...state,
        isGetUserFriends: false,
      };
    default:
      return state;
  }
};
