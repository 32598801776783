import types from "../types/stockImages";
const initialState = {
  isStockImages: false,
  stockImages: [],
  stockImagesError: false,
  stockImagesPage: 1,
  isStockImagesPagination: false,
  stockImagesSearch: "",
  noMoreStockImages: false,
  redditImages: [],
  isRedditImages: false,
  isRedditImagesError: false,
  isRedditImagesErrorMsg: [],
  isRedditImagesPagination: false,
  redditImagesAfter: null,
  selectedPhotosTab: "stock",
  redditSearch: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    //
    // FETCH STOCK_IMAGES
    //
    case types.GET_STOCK_IMAGES_REQUEST:
      return {
        ...state,
        isStockImages: true,
        stockImagesSearch: action.payload.search,
        stockImagesPage: 1,
        noMoreStockImages: false,
        // stockImages: [],
        isStockImagesPagination: false,
        // redditImages: [],
      };
    case types.GET_STOCK_IMAGES_SUCCESS:
      return {
        ...state,
        isStockImages: false,
        stockImages: action.payload.data.length
          ? action.payload.data.filter((i) => i.id !== 2426)
          : [],
      };

    case types.GET_STOCK_IMAGES_ERROR:
      return {
        ...state,
        isStockImages: false,
        stockImagesError: true,
      };
    //
    // FETCH STOCK_IMAGES PAGINATION
    //
    case types.GET_STOCK_IMAGES_PAGINATION_REQUEST:
      return {
        ...state,
        isStockImagesPagination: true,
      };
    case types.GET_STOCK_IMAGES_PAGINATION_SUCCESS:
      return {
        ...state,
        isStockImagesPagination: false,
        stockImages: state.stockImages.concat(
          action.payload.data.filter((i) => i.id !== 2426)
        ),
        stockImagesPage: state.stockImagesPage + 1,
        noMoreStockImages: action.payload.data.length ? false : true,
      };

    case types.GET_STOCK_IMAGES_PAGINATION_ERROR:
      return {
        ...state,
        isStockImagesPagination: false,
        stockImagesError: true,
      };
    //
    // GET_REDDIT_IMAGES
    //
    case types.GET_REDDIT_IMAGES_REQUEST:
      return {
        ...state,
        isRedditImages: true,
        redditSearch: action.payload.search,
        // redditImages: [],
        // stockImages: [],
      };
    case types.GET_REDDIT_IMAGES_SUCCESS:
      return {
        ...state,
        isRedditImages: false,
        redditImages: action.payload.children,
        redditImagesAfter: action.payload.data.data.after,
      };

    case types.GET_REDDIT_IMAGES_ERROR:
      return {
        ...state,
        isRedditImages: false,
      };

    //
    // GET_REDDIT_IMAGES_MORE
    //
    case types.GET_REDDIT_IMAGES_PAGINATION_REQUEST:
      return {
        ...state,
        isRedditImagesPagination: true,
        redditSearch: action.payload.search,
      };
    case types.GET_REDDIT_IMAGES_PAGINATION_SUCCESS:
      return {
        ...state,
        isRedditImagesPagination: false,
        redditImages: [...state.redditImages, ...action.payload.children],
        redditImagesAfter: action.payload.data.data.after,
      };

    case types.GET_REDDIT_IMAGES_PAGINATION_ERROR:
      return {
        ...state,
        isRedditImagesPagination: false,
      };

    //
    // CHANGE_PHOTOS_TAB
    //
    case types.CHANGE_PHOTOS_TAB:
      return {
        ...state,
        selectedPhotosTab: action.payload,
      };

    default:
      return state;
  }
};
