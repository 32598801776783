import fetchService from './index';

export const getUnSplashImages = ({ url, checkCode }) => fetchService({
  method: 'GET',
  url,
  isUrl: true,
  checkCode,
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const getStickers = ({ url, checkCode }) => fetchService({
  method: 'GET',
  url,
  isUrl: true,
  checkCode,
  myHeaders: {
    // "Content-Type": "application/json",
  },
});
