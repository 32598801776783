import { API_ENDPOINT, APP_MEMES_AUTH_ENDPOINT } from 'shared/constants';
import $ from 'jquery';

const fetchService = ({
  method, url, body, myHeaders, isUrl, checkCode,
}) => fetch(`${isUrl === true ? url : `${API_ENDPOINT}api${url}`}`, {
  method, // *GET, POST, PUT, DELETE, etc.
  headers: {
    ...myHeaders,
    Accept: 'application/json',
    // Authorization: `Bearer ${Token}`,
  },
  body,
})
  .then((res) => res.json())
  .then((response) => {
    if (checkCode && checkCode === 'unsplash') {
      if (response.errors) {
        throw response;
      } else {
        return response;
      }
    } else {
      if (response.meta && response.meta.status === 200) {
        return response;
      }
      if (response.error === false) {
        return response;
      }
      throw response;
    }
  })
  .catch((error) => {
    console.log('@fetchService error =>', { url, error });
    throw error;
  });

export function checkBarrierValid() {
  return $.ajax({
    url: `${APP_MEMES_AUTH_ENDPOINT}auth-check`,
    type: 'get',
    crossDomain: true,
    xhrFields: {
      withCredentials: true,
    },
  });
}

export function memeAuthenticate() {
  return $.ajax({
    url: 'https://app.memes.com/api/authenticate',
    type: 'get',
    crossDomain: true,
    xhrFields: {
      withCredentials: true,
    },
  });
}

export * from './gifs';
export * from './profile';
export * from './auth';
export * from './bgImages';
export * from './templates';
export * from './payments';
export default fetchService;
