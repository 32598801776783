import { getUserData } from 'shared/utilities';
import { APP_MEMES_API_ENDPOINT } from 'shared/constants';
import fetchService from './index';

export const uploadMediaApi = ({ body, isFormData }) => {
  const headers = {};
  if (!isFormData) {
    headers['Content-Type'] = 'application/json';
  }
  return fetchService({
    method: 'POST',
    url: '/uploadMemeFiles',
    body: isFormData ? body : JSON.stringify(body),
    isUrl: false,
    myHeaders: {
      ...headers,
    },
  });
};

export const getUserMedia = ({
  user_id, type, per_page, page_id,
}) => fetchService({
  method: 'POST',
  url: `/user-uploads?user_id=${user_id}`,
  isUrl: false,
  body: JSON.stringify({
    user_id: getUserData().id,
    login_token: getUserData().login_token,
    per_page,
    type,
    page_id,
  }),
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const saveUserDesign = (data) => fetchService({
  method: 'POST',
  url: '/insert_user_design',
  isUrl: false,
  body: data,
  myHeaders: {},
});

export const saveExistingUserDesign = (data) => fetchService({
  method: 'POST',
  url: '/edit_user_design',
  isUrl: false,
  body: data,
  myHeaders: {},
});

export const getUserDesigns = ({
  page_id,
  search,
  design_type,
  category,
  per_page,
}) => fetchService({
  method: 'POST',
  url: `/get_user_all_designs/?user_id=${
    getUserData().id
  }&page_id=${page_id}${search ? `&search=${search}` : ''}`,
  body: JSON.stringify({
    user_id: getUserData().id,
    login_token: getUserData().login_token,
    design_type,
    category,
    per_page,
    page_id,
  }),
  isUrl: false,
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const getSavedJson = (design_id) => fetchService({
  method: 'POST',
  body: JSON.stringify({
    user_id: getUserData().id,
    login_token: getUserData().login_token,
  }),
  url: `/get_user_all_designs/?design_id=${design_id}&page_id=1`,
  isUrl: false,
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const getMediaFromLink = ({ link, imagesId, type }) => fetchService({
  method: 'POST',
  body: JSON.stringify({
    user_id: getUserData().id,
    login_token: getUserData().login_token,
    link,
    imagesId,
    type,
  }),
  url: '/userDirectuUploadLink',
  isUrl: false,
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const deleteUserDesign = (design_id) => fetchService({
  method: 'POST',
  body: JSON.stringify({
    user_id: getUserData().id,
    login_token: getUserData().login_token,
  }),
  url: `/delete_user_design/?design_id=${design_id}`,
  isUrl: false,
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const deleteUserMedia = ({ media_id }) => fetchService({
  method: 'POST',
  body: JSON.stringify({
    user_id: getUserData().id,
    login_token: getUserData().login_token,
    media_id,
  }),
  url: '/deleteUserMedia',
  isUrl: false,
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const downloadMeme = ({ body, isStatic }) => fetchService({
  method: 'POST',
  url: isStatic ? '/download_meme_image' : '/download_meme',
  isUrl: false,
  body,
  myHeaders: {},
});

export const getUserFriends = ({ user_id, login_token, device_token }) => fetchService({
  method: 'POST',
  // https://app.memes.com/api/searchByTypes
  url: `${APP_MEMES_API_ENDPOINT}api/searchByTypes`,
  isUrl: true,
  body: JSON.stringify({
    searchType: 'people',
    searchText: 'ramneet',
    page: 1,
    user_id,
    login_token,
    device_token,
  }),
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const updateMyGuestDesigns = (guestDesignId) => fetchService({
  method: 'POST',
  url: `/updateDesignsUser?user_id=${
    getUserData().id
  }&guest_design_id=${guestDesignId}`,
  isUrl: false,
});
