import React from "react";
import { store } from "react-notifications-component";
import $ from "jquery";

const createNotification = async ({ type, title, msg, timeout }) => {
  hidePreviousAlerts();
  Array.from(
    document.getElementsByClassName("notification-container--top-right")
  ).forEach(function (element, index, array) {
    element.addEventListener("click", function () {
      if (element.hasChildNodes()) {
        $(".notification").css("display", "none");
      }
    });
  });
  return (
    <>
      {store.addNotification({
        title: title,
        message: msg,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          delay: 0,
          duration: timeout ? timeout : 3000,
          showIcon: true,
          click: true,
        },
      })}
    </>
  );
};
export default createNotification;

const hidePreviousAlerts = async () => {
  let divs = document.querySelectorAll(".notification-container--top-right");
  if (divs.length) {
    if (divs[0].hasChildNodes()) {
      $(".notification").css("display", "none");
    }
  }
};
