import types from "../types/bgImages";
const initialState = {
  isStickers: false,
  isStickersError: false,
  stickers: null,
  stickersClone: null,
  stickersPage: 5,
  isMoreStickers: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //
    // GET_STICKERS
    //
    case types.GET_STICKERS_REQUEST:
      return {
        ...state,
        isStickers: true,
        isStickersError: false,
        isMoreStickers: true,
        stickersPage: 5,
        // stickersClone: null,
        // stickers: null
      };
    case types.GET_STICKERS_SUCCESS:
      return {
        ...state,
        isStickers: false,
        stickers: action.payload,
        stickersClone: action.stickersClone,
      };

    case types.GET_STICKERS_ERROR:
      return {
        ...state,
        isStickers: false,
        isStickersError: true,
        stickers: null,
        stickersClone: null,
        stickersPage: 5,
      };
    // GET_STICKERS_CHUNKS
    case types.GET_STICKERS_CHUNKS:
      return {
        ...state,
        stickers: {
          ...state.stickers,
          ...action.payload,
        },
      };
    // GET_STICKERS_PAGINATION
    case types.GET_STICKERS_PAGINATION:
      let new_data = {};
      console.log("state.stickersClone ====", state.stickersClone);
      if (state.stickersClone !== null) {
        Object.keys(state.stickersClone).map((i, j) => {
          if (j === state.stickersPage) {
            new_data[i] = state.stickersClone[i];
          }
        });
        
        return {
          ...state,
          stickersPage: action.payload.stickersPage,
          stickers: {
            ...state.stickers,
            ...new_data,
          },
          isMoreStickers:
          action.payload.stickersPage - 1 ===
          Object.keys(state.stickersClone).length
          ? false
          : true,
        };
      } else {
        return {
          ...state
        }
      }
    case types.EMPTY_STICKERS_STATE:
      return {
        ...state,
        stickers: null,
        stickersClone: null,
        stickersPage: 5,
        isMoreStickers: true,
      };
    default:
      return state;
  }
};
