import {
  ALLOWED_IMAGE_TYPES,
  MEMES_COOKIE,
  GUEST_USER_ID,
  DUMMY_LOGIN_TOKEN,
} from "shared/constants";
import { restrictedList } from "shared/restrictedSearchKeyworks";
import { v4 as uuidv4 } from "uuid";

export const getFileMetaData = (file) => {
  let ext = null;
  let type = null;
  let status = false;
  let fileSize = 0;
  let msg = null;
  let title = null;
  const data = file.type.split("/");
  fileSize = Math.round(file.size / 1024 / 1024);
  if (fileSize > 20) {
    status = false;
    title = "File size exceeds 20mb";
    msg = "File size must 20mb or below";
  } else if (data[0] === "image") {
    if (ALLOWED_IMAGE_TYPES.includes(data[1])) {
      type = "image";
      status = true;
      ext = data[1];
    } else {
      status = false;
      ext = null;
      type = null;
      title = "File type not yet supported";
      msg = "Please upload an image or gif file";
    }
  } else if (data[0] === "video") {
    status = false;
    ext = null;
    type = null;
    title = "File type not yet supported";
    msg = "Please upload an image or gif file";
    // if (ALLOWED_VIDEO_TYPES.includes(data[1])) {
    //   type = "video";
    //   status = true;
    //   ext = data[1];
    // } else {
    //   status = false;
    //   ext = null;
    //   type = null;
    //   msg = "unsupported video type";
    // }
  } else {
    status = false;
    ext = null;
    type = null;
    title = "File type not supported";
    msg = "Please upload an image or gif file";
  }
  return {
    status,
    ext,
    type,
    msg,
    title,
  };
};

export const getPercentValue = (value, total) =>
  ((value / total) * 100).toFixed(2) * 100;

export const getCookie = () => {
  if (
    process.env.REACT_APP_URL === "staging" ||
    process.env.REACT_APP_URL === "dev"
  ) {
    const name = "memes-generator";
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      const cookie_data = parts.pop().split(";").shift();
      const data = {
        // id: 3750368,
        // id: 4036664,
        profile_image: "https://memes.com/profile/4036664/avatarThumb",
        login_token: DUMMY_LOGIN_TOKEN,
        email: "hardeep.s@iapptechnologies.com",
        // customer_id: "cus_JGpq6SfPpzoYY9",
        // subscription_id: "sub_JGqFJmWby5Sx8p",
        // subscription_status: 1,
        ...JSON.parse(cookie_data),
      };
      return JSON.stringify(data);
    }
    return null;
  }
  const name = "memes-generator";
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookie_data = parts.pop().split(";").shift();
    // let user = JSON.parse(cookie_data);
    const data = {
      // profile_image: "https://cdn.memes.com/avatar/thumb.jpg",
      // login_token: user.loginToken,
      // id: user.id,
      // userType: user.userType,
      // user_id: user.user_id,
      // user_name: user.user_name,
      // email: user.email,
      // full_name: user.full_name,
      login_token: DUMMY_LOGIN_TOKEN,
      ...JSON.parse(cookie_data),
    };
    return JSON.stringify(data);
  }
  return null;
};

export const updateCookie = (data) => {
  document.cookie = `memes-generator=${JSON.stringify({
    ...getUserData(),
    ...data,
    profile_image: `https://memes.com/profile/${getUserData().id}/avatarThumb`,
  })};path=/;domain=${MEMES_COOKIE}`;
};

export const getUserData = () => {
  const data = {
    ...JSON.parse(getCookie()),
  };
  return data;
};

export const getQueryString = (query, value) => {
  const str = new URLSearchParams(query);
  const v = str.get(value);
  if (v) return v;
  return null;
};
export const base64toBlob = (b64Data, contentType) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const urltoFile = (data_url, filename, mimeType) =>
  fetch(data_url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }))
    .catch((error) => {
      console.log("@urltoFile error", error);
    });

export const filterRedditResults = (data) =>
  data.filter((i) => {
    if (
      i.data.preview &&
      isValidUrl(i.data.thumbnail) &&
      Object.keys(i.data.preview.images[0].variants).length === 0
    ) {
      return i;
    }
  });

export const isValidUrl = (string) => {
  try {
    new URL(string);
  } catch (_) {
    // console.log("reddit not valid url @@", string)
    return false;
  }
  return true;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const toggleRgbAlpha = (rgb) => {
  const old_alpha = rgb.charAt(rgb.length - 2);
  const new_alpha = old_alpha === "1" ? 0 : 1;
  const new_rgb =
    rgb.substring(0, rgb.length - 2) +
    new_alpha +
    rgb.substring(rgb.length - 1);
  return new_rgb;
};

export const setCookieForTesting = ({ id, isGuestUser, guest_design_id }) => {
  console.log("@setCookieForTesting===isGuestUser", isGuestUser);
  const guestDate = {};
  if (isGuestUser) {
    guestDate["guest_design_id"] = guest_design_id;
    guestDate.isGuestUser = isGuestUser;
  }
  document.cookie = `memes-generator=${JSON.stringify({
    id: isGuestUser ? GUEST_USER_ID : id,
    // guest_design_id: guest_design_id,
    username: "ramneetchopra",
    profile_image: `https://memes.com/profile/${id}/avatarThumb`,
    email: "hardeep.s@iapptechnologies.com",
    ...guestDate,
  })};path=/;domain=${MEMES_COOKIE}`;
};

export const saveMemeGeneratorCookie = ({ data }) => {
  document.cookie = `memes-generator=${JSON.stringify({
    ...data,
    profile_image: `https://memes.com/profile/${data.id}/avatarThumb`,
  })};path=/;domain=${MEMES_COOKIE}`;
};
export const isSafeSearch = (str) => {
  // let searchText = str.replace(/[^a-zA-Z ]/g, "");
  const searchText = str.replace(/[&\/\\#,+()$~%@.'":*?<>{}]/g, "");
  const searchArray = searchText
    .toLowerCase()
    .replace(/\s/g, ",")
    .split(",")
    .filter((i) => i !== "");
  const promises = [];
  const p = new Promise((resolve, reject) => {
    if (restrictedList.includes(searchArray.join(""))) {
      reject();
    } else {
      resolve();
    }
  });
  promises.push(p);

  searchArray.map((i) => {
    const p = new Promise((resolve, reject) => {
      if (restrictedList.includes(i)) {
        reject();
      } else {
        resolve();
      }
    });
    promises.push(p);
  });
  return Promise.all(promises);
};

export const checkImageURLExtension = (url) =>
  url.match(/\.(jpeg|jpg|gif|png)$/) != null;

export const userIdPrompt = () => {
  let id;
  const enteredUserId = prompt(
    "Please enter user id(atleast min 6 digits and must be number only) e.g.\n3750368\n4036664",
    "3750368"
  );
  if (enteredUserId == null || enteredUserId == "") {
    id = 3750368;
    // 3750368
    // 4036664
  } else {
    id = +enteredUserId ? +enteredUserId : 3750368;
  }
  return id;
};

export const isGuestUserPrompt = () => confirm("Are you a guest user?");

export const timestampToDateString = (UNIX_timestamp) => {
  const a = new Date(UNIX_timestamp * 1000);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  const time = `${month} ${date}, ${year}`;
  // " " + hour + ":" + min + ":" + sec;

  return {
    time,
    year,
    month,
    date,
    hour,
    min,
    sec,
  };
};

export const getUnitPrice = (price) => price / 100;

export const getDateString = (d) => {
  const s = d.split(" ")[0];
  const date = new Date(s).toDateString();
  const r = date.split(" ");
  const new_s = `${r[1]} ${r[2]}, ${r[3]}`;
  return new_s;
};

export const clearMemeSession = () => {
  window.sessionStorage.removeItem("memes-progress");
};

export const isFirefox = () =>
  navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

export const createGuestDesignId = () => {
  const uuid = uuidv4();
  const id = `${uuid}--${Date.now()}`;
  localStorage.setItem("memes_guest_design_id", id);
  return id;
};

export const getGuestDesignId = () => {
  const guest_design_id = localStorage.getItem("memes_guest_design_id");
  return guest_design_id || null;
};

export const removeGuestDesgnId = () => {
  localStorage.removeItem("memes_guest_design_id");
};
