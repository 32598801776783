import { getUserData } from 'shared/utilities';
import fetchService from './index';

export const getUserSubscriptionStatus = () => fetchService({
  method: 'POST',
  url: `/userSubscriptionStatus?login_token=${
    getUserData().login_token
  }&user_id=${getUserData().id}`,
  isUrl: false,
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const getStripeProducts = () => fetchService({
  method: 'POST',
  url: `/listOfAllStripeProducts?login_token=${
    getUserData().login_token
  }&user_id=${getUserData().id}`,
  isUrl: false,
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const addCardToStripe = ({ formData }) => fetchService({
  method: 'POST',
  url: '/addCustomerCard',
  isUrl: false,
  body: formData,
  myHeaders: {},
});

export const retrieveUserSubscription = () => fetchService({
  method: 'POST',
  url: '/retrieveUserSubscription',
  isUrl: false,
  body: JSON.stringify({
    subscription_id: getUserData().subscription_id,
    user_id: getUserData().id,
    login_token: getUserData().login_token,
  }),
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const deleteUserSubscription = () => fetchService({
  method: 'POST',
  url: '/deleteUserSubscription',
  isUrl: false,
  body: JSON.stringify({
    subscription_id: getUserData().subscription_id,
    user_id: getUserData().id,
    login_token: getUserData().login_token,
  }),
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const getUserSavedCards = () => fetchService({
  method: 'POST',
  url: '/listOfAllCards',
  isUrl: false,
  body: JSON.stringify({
    customer_id: getUserData().customer_id,
    user_id: getUserData().id,
    login_token: getUserData().login_token,
  }),
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const setUserDefaultCard = (card_id) => fetchService({
  method: 'POST',
  url: '/setDefaultCard',
  isUrl: false,
  body: JSON.stringify({
    customer_id: getUserData().customer_id,
    user_id: getUserData().id,
    login_token: getUserData().login_token,
    card_id,
  }),
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const removeUserCard = (card_id, fingerprint) => fetchService({
  method: 'POST',
  url: '/deleteUserCard',
  isUrl: false,
  body: JSON.stringify({
    customer_id: getUserData().customer_id,
    user_id: getUserData().id,
    login_token: getUserData().login_token,
    card_id,
    fingerprint,
  }),
  myHeaders: {
    'Content-Type': 'application/json',
  },
});

export const addUserCard = (token) => fetchService({
  method: 'POST',
  url: '/addUserCard',
  isUrl: false,
  body: JSON.stringify({
    email: getUserData().email,
    customer_id: getUserData().customer_id,
    user_id: getUserData().id,
    login_token: getUserData().login_token,
    token,
  }),
  myHeaders: {
    'Content-Type': 'application/json',
  },
});
