import types from "../types/payments";
import * as API from "../../services";
import { updateCookie } from "shared/utilities";
import createNotification from "components/common/createNotification";

export const getUserSubscriptionStatus = () => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_USER_SUBSCRIPTION_STATUS_REQUEST,
    });
    try {
      const { data, error } = await API.getUserSubscriptionStatus();
      updateCookie(data);
      return dispatch({
        type: types.GET_USER_SUBSCRIPTION_STATUS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error getUserSubscriptionStatus", error);
      // createNotification({
      //   title: "Something went wrong.",
      //   type: "danger",
      //   timeout: 10000,
      //   msg:
      //     "Can't get your subscription status right now. Although you can still create your memes. We are working on this.",
      // });
      return dispatch({
        type: types.GET_USER_SUBSCRIPTION_STATUS_ERROR,
        payload: error.message,
      });
    }
  };
};

export const getStripeProducts = () => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_STRIPE_PRODUCTS_REQUEST,
    });
    try {
      const { data } = await API.getStripeProducts();
      return dispatch({
        type: types.GET_STRIPE_PRODUCTS_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      console.log("error getStripeProducts", error);
      return dispatch({
        type: types.GET_STRIPE_PRODUCTS_ERROR,
        payload: error.message,
      });
    }
  };
};

export const addCardToStripe = (formData) => {
  return async (dispatch) => {
    dispatch({
      type: types.ADD_CARD_TO_STRIPE_REQUEST,
    });
    try {
      const { data } = await API.addCardToStripe({
        formData,
      });
      return dispatch({
        type: types.ADD_CARD_TO_STRIPE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error addCardToStripe", error);
      return dispatch({
        type: types.ADD_CARD_TO_STRIPE_ERROR,
        payload: error.message,
      });
    }
  };
};

export const retrieveUserSubscription = () => {
  return async (dispatch) => {
    dispatch({
      type: types.RETRIEVE_USER_SUBSCRIPTION_REQUEST,
    });
    try {
      const { data } = await API.retrieveUserSubscription();
      return dispatch({
        type: types.RETRIEVE_USER_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error retrieveUserSubscription", error);
      return dispatch({
        type: types.RETRIEVE_USER_SUBSCRIPTION_ERROR,
        payload: error.message,
      });
    }
  };
};

export const deleteUserSubscription = () => {
  return async (dispatch) => {
    dispatch({
      type: types.DELETE_USER_SUBSCRIPTION_REQUEST,
    });
    try {
      const { data } = await API.deleteUserSubscription();
      return dispatch({
        type: types.DELETE_USER_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error deleteUserSubscription", error);
      return dispatch({
        type: types.DELETE_USER_SUBSCRIPTION_ERROR,
        payload: error.message,
      });
    }
  };
};

export const getUserSavedCards = () => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_USER_SAVED_CARDS_REQUEST,
    });
    try {
      const { data } = await API.getUserSavedCards();
      return dispatch({
        type: types.GET_USER_SAVED_CARDS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error getUserSavedCards", error);
      return dispatch({
        type: types.GET_USER_SAVED_CARDS_ERROR,
        payload: error.message,
      });
    }
  };
};

export const setUserDefaultCard = (card_id) => {
  return async (dispatch) => {
    dispatch({
      type: types.SET_USER_DEFAULT_CARD_REQUEST,
    });
    try {
      const { data } = await API.setUserDefaultCard(card_id);
      return dispatch({
        type: types.SET_USER_DEFAULT_CARD_SUCCESS,
        payload: data.default_source,
      });
    } catch (error) {
      console.log("error setUserDefaultCard", error);
      return dispatch({
        type: types.SET_USER_DEFAULT_CARD_ERROR,
        payload: error.message,
      });
    }
  };
};

export const removeUserCard = (card_id, fingerprint) => {
  return async (dispatch) => {
    dispatch({
      type: types.REMOVE_USER_CARD_REQUEST,
    });
    try {
      const { data, default_source } = await API.removeUserCard(
        card_id,
        fingerprint
      );
      console.log("data====", data, "default_source", default_source);
      return dispatch({
        type: types.REMOVE_USER_CARD_SUCCESS,
        payload: { id: data.id, default_source },
      });
    } catch (error) {
      console.log("error setUserDefaultCard", error);
      return dispatch({
        type: types.REMOVE_USER_CARD_ERROR,
        payload: error.message,
      });
    }
  };
};

export const addUserCard = (token) => {
  return async (dispatch) => {
    dispatch({
      type: types.ADD_USER_CARD_REQUEST,
    });
    try {
      const { data } = await API.addUserCard(token);
      return dispatch({
        type: types.ADD_USER_CARD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error setUserDefaultCard", error);
      return dispatch({
        type: types.ADD_USER_CARD_ERROR,
        payload: error.message,
      });
    }
  };
};
