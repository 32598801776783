import types from "../types/profile";
import * as API from "../../services";
import { APP_MEMES_API_ENDPOINT, MEME_API_DEFAULTS } from "shared/constants";
import $ from "jquery";

export const uploadMediaApi = ({ body, isFormData, type }) => {
  return async (dispatch) => {
    dispatch({
      type: types.UPLOAD_MEDIA_REQUEST,
    });
    try {
      const { data, error } = await API.uploadMediaApi({
        body,
        isFormData,
        type,
      });
      return dispatch({
        type: types.UPLOAD_MEDIA_SUCCESS,
        payload: { data, error },
      });
    } catch (error) {
      console.log("error uploadMediaApi", error);
      return dispatch({
        type: types.UPLOAD_MEDIA_ERROR,
        payload: error.message,
      });
    }
  };
};

export const getUserMedia = ({ user_id, type, per_page, page_id }) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_USER_MEDIA_REQUEST,
      payload: { type, page_id },
    });
    try {
      const { data } = await API.getUserMedia({
        user_id,
        type,
        per_page,
        page_id,
      });
      return dispatch({
        type: types.GET_USER_MEDIA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error getUserMedia", error);
      return dispatch({
        type: types.GET_USER_MEDIA_ERROR,
        payload: error.message,
      });
    }
  };
};
export const getUserMediaPagination = ({
  user_id,
  type,
  per_page,
  page_id,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_USER_MEDIA_PAGINATION_REQUEST,
      payload: { type, page_id },
    });
    try {
      const { data } = await API.getUserMedia({
        user_id,
        type,
        per_page,
        page_id,
      });
      return dispatch({
        type: types.GET_USER_MEDIA_PAGINATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error getUserMedia", error);
      return dispatch({
        type: types.GET_USER_MEDIA_PAGINATION_ERROR,
        payload: error.message,
      });
    }
  };
};
export const deleteUserMedia = ({ media_id }) => {
  return async (dispatch) => {
    dispatch({
      type: types.DELETE_USER_MEDIA_REQUEST,
    });
    try {
      await API.deleteUserMedia({ media_id });
      return dispatch({
        type: types.DELETE_USER_MEDIA_SUCCESS,
        payload: { id: media_id },
      });
    } catch (error) {
      console.log("error deleteUserMedia", error);
      return dispatch({
        type: types.DELETE_USER_MEDIA_ERROR,
        payload: error.message,
      });
    }
  };
};

export const saveUserDesign = (formData) => {
  return async (dispatch) => {
    dispatch({
      type: types.SAVE_USER_DESIGN_REQUEST,
    });
    try {
      const { data } = await API.saveUserDesign(formData);
      return dispatch({
        type: types.SAVE_USER_DESIGN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error saveUserDesign", error);
      return dispatch({
        type: types.SAVE_USER_DESIGN_ERROR,
        payload: error.message,
      });
    }
  };
};

export const saveExistingUserDesign = (formData) => {
  return async (dispatch) => {
    dispatch({
      type: types.SAVE_EXISTING_USER_DESIGN_REQUEST,
    });
    try {
      const { data } = await API.saveExistingUserDesign(formData);
      return dispatch({
        type: types.SAVE_EXISTING_USER_DESIGN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error saveExistingUserDesign", error);
      return dispatch({
        type: types.SAVE_EXISTING_USER_DESIGN_ERROR,
        payload: error.message,
      });
    }
  };
};

export const getUserDesigns = ({
  page_id,
  user_id,
  search,
  design_type,
  category,
  per_page,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_USER_DESIGNS_REQUEST,
    });
    try {
      const { data, total_records } = await API.getUserDesigns({
        page_id,
        user_id,
        search,
        design_type,
        category,
        per_page,
      });
      return dispatch({
        type: types.GET_USER_DESIGNS_SUCCESS,
        payload: { data, total_records, search },
      });
    } catch (error) {
      console.log("error getUserDesigns", error);
      return dispatch({
        type: types.GET_USER_DESIGNS_ERROR,
        payload: error.message,
      });
    }
  };
};
export const getUserDesignsPagination = ({
  page_id,
  user_id,
  search,
  design_type,
  category,
  per_page,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_USER_DESIGNS_MORE_REQUEST,
    });
    try {
      const { data, total_records } = await API.getUserDesigns({
        page_id,
        user_id,
        search,
        design_type,
        category,
        per_page,
      });
      return dispatch({
        type: types.GET_USER_DESIGNS_MORE_SUCCESS,
        payload: { data, total_records, page_id, search },
      });
    } catch (error) {
      console.log("error getUserDesignsPagination", error);
      return dispatch({
        type: types.GET_USER_DESIGNS_MORE_ERROR,
        payload: error.message,
      });
    }
  };
};

export const getUserDesignsAll = ({
  page_id,
  per_page,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_USER_DESIGNS_ALL_REQUEST,
    });
    try {
      const { data, total_records } = await API.getUserDesigns({
        page_id,
        per_page,
      });
      return dispatch({
        type: types.GET_USER_DESIGNS_ALL_SUCCESS,
        payload: { data, total_records},
      });
    } catch (error) {
      console.log("error getUserDesigns", error);
      return dispatch({
        type: types.GET_USER_DESIGNS_ALL_ERROR,
        payload: error.message,
      });
    }
  };
};
export const getUserDesignsAllPagination = ({
  page_id,
  per_page,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_USER_DESIGNS_ALL_MORE_REQUEST,
    });
    try {
      const { data, total_records } = await API.getUserDesigns({
        page_id,
        per_page,
      });
      return dispatch({
        type: types.GET_USER_DESIGNS_ALL_MORE_SUCCESS,
        payload: { data, total_records, page_id },
      });
    } catch (error) {
      console.log("error getUserDesignsPagination", error);
      return dispatch({
        type: types.GET_USER_DESIGNS_ALL_MORE_ERROR,
        payload: error.message,
      });
    }
  };
};

export const deleteUserDesign = (id) => {
  return async (dispatch) => {
    dispatch({
      type: types.DELETE_USER_DESIGN_REQUEST,
    });
    try {
      await API.deleteUserDesign(id);
      return dispatch({
        type: types.DELETE_USER_DESIGN_SUCCESS,
        payload: id,
      });
    } catch (error) {
      console.log("error deleteUserDesign", error);
      return dispatch({
        type: types.DELETE_USER_DESIGN_ERROR,
        payload: error.message,
      });
    }
  };
};

export const getSavedJson = (design_id) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_SAVED_JSON_REQUEST,
    });
    try {
      const { data } = await API.getSavedJson(design_id);
      return dispatch({
        type: types.GET_SAVED_JSON_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error getSavedJson", error);
      return dispatch({
        type: types.GET_SAVED_JSON_ERROR,
        payload: error.message,
      });
    }
  };
};

export const downloadMeme = ({ body, isStatic }) => {
  return async (dispatch) => {
    dispatch({
      type: types.DOWNLOAD_MEME_REQUEST,
    });
    try {
      const { data } = await API.downloadMeme({ body, isStatic });
      return dispatch({
        type: types.DOWNLOAD_MEME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error downloadMeme", error);
      return dispatch({
        type: types.DOWNLOAD_MEME_ERROR,
        payload: error.message,
      });
    }
  };
};

export const getUserFriends = ({
  user_id,
  login_token,
  device_token,
  searchText,
}) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_USER_FRIENDS_REQUEST,
    });
    try {
      const { data } = await callApi({
        user_id,
        login_token,
        device_token,
        searchText,
      });
      return dispatch({
        type: types.GET_USER_FRIENDS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error getUserFriends", error);
      debugger;
      return dispatch({
        type: types.GET_USER_FRIENDS_ERROR,
        payload: error,
      });
    }
  };
};

export const callApi = ({
  searchText,
  searchType,
  page,
  user_id,
  login_token,
  device_token,
  is_public,
}) => {
  var settings = {
    url: APP_MEMES_API_ENDPOINT + "api/searchByTypes",
    method: "post",
    // timeout: 0,
    crossDomain: true,
    // xhrFields: {
    //   withCredentials: true,
    // },
    data: {
      user_id,
      login_token,
      device_token,
      is_public,
      searchType: searchType,
      searchText,
      page,
      page_size: 20,
    },
  };
  return $.ajax(settings);
};

export const postMeme = ({ postText, file, taggedUsers, tags, auth }) => {
  return async (dispatch) => {
    dispatch({
      type: types.POST_MEME_REQUEST,
    });
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("postText", postText);
      if (tags) {
        formData.append("tags", tags);
      }
      if (taggedUsers) {
        formData.append("taggedUsers", JSON.stringify(taggedUsers));
      }
      formData.append("user_id", auth.user_id);
      formData.append("login_token", auth.loginToken);
      formData.append("device_token", MEME_API_DEFAULTS.device_token);
      formData.append("is_public", MEME_API_DEFAULTS.is_public);
      const data = await callApiPostMeme(formData);
      return dispatch({
        type: types.POST_MEME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error postMeme", error);
      return dispatch({
        type: types.POST_MEME_ERROR,
        payload: error,
      });
    }
  };
};

export const callApiPostMeme = (formData) => {
  var settings = {
    crossDomain: true,
    url: APP_MEMES_API_ENDPOINT + "api/addMemePostTag",
    method: "POST",
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
  };
  return $.ajax(settings);
};

export const getMediaFromLink = ({ link, imagesId, type }) => {
  return async (dispatch) => {
    dispatch({
      type: types.UPLOAD_MEDIA_REQUEST,
    });
    try {
      const { data } = await API.getMediaFromLink({ link, imagesId, type });
      return dispatch({
        type: types.UPLOAD_MEDIA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error getSavedJson", error);
      return dispatch({
        type: types.UPLOAD_MEDIA_ERROR,
        payload: error.message,
      });
    }
  };
};


export const updateMyGuestDesigns = (guestDesignId) => {
  return async (dispatch) => {
    dispatch({
      type: types.UPDATE_MY_GUEST_DESIGNS_REQUEST,
    });
    try {
      const { data } = await API.updateMyGuestDesigns(guestDesignId);
      return dispatch({
        type: types.UPDATE_MY_GUEST_DESIGNS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log("error getSavedJson", error);
      return dispatch({
        type: types.UPDATE_MY_GUEST_DESIGNS_ERROR,
        payload: error.message,
      });
    }
  };
}
