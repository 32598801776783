const types = {
  GET_STICKERS_REQUEST: "GET_STICKERS_REQUEST",
  GET_STICKERS_SUCCESS: "GET_STICKERS_SUCCESS",
  GET_STICKERS_ERROR: "GET_STICKERS_ERROR",

  EMPTY_STICKERS_STATE: "EMPTY_STICKERS_STATE",

  GET_STICKERS_CHUNKS: "GET_STICKERS_CHUNKS",

  GET_STICKERS_PAGINATION: "GET_STICKERS_PAGINATION",
};
export default types;
