const types = {
  GET_STOCK_IMAGES_REQUEST: "GET_STOCK_IMAGES_REQUEST",
  GET_STOCK_IMAGES_SUCCESS: "GET_STOCK_IMAGES_SUCCESS",
  GET_STOCK_IMAGES_ERROR: "GET_STOCK_IMAGES_ERROR",

  GET_STOCK_IMAGES_PAGINATION_REQUEST: "GET_STOCK_IMAGES_PAGINATION_REQUEST",
  GET_STOCK_IMAGES_PAGINATION_SUCCESS: "GET_STOCK_IMAGES_PAGINATION_SUCCESS",
  GET_STOCK_IMAGES_PAGINATION_ERROR: "GET_STOCK_IMAGES_PAGINATION_ERROR",

  GET_REDDIT_IMAGES_REQUEST: "GET_REDDIT_IMAGES_REQUEST",
  GET_REDDIT_IMAGES_SUCCESS: "GET_REDDIT_IMAGES_SUCCESS",
  GET_REDDIT_IMAGES_ERROR: "GET_REDDIT_IMAGES_ERROR",

  GET_REDDIT_IMAGES_PAGINATION_REQUEST: "GET_REDDIT_IMAGES_PAGINATION_REQUEST",
  GET_REDDIT_IMAGES_PAGINATION_SUCCESS: "GET_REDDIT_IMAGES_PAGINATION_SUCCESS",
  GET_REDDIT_IMAGES_PAGINATION_ERROR: "GET_REDDIT_IMAGES_PAGINATION_ERROR",

  CHANGE_PHOTOS_TAB: "CHANGE_PHOTOS_TAB",
};
export default types;
