import React from "react";
import BgImage from "images/icons/login-bg.png";
import { WATERMARK_URL } from "shared/constants";

const NotFound = ({}) => {
  return (
    <div
      className="login-bg"
      style={{ position: "relative", backgroundImage: `url(${BgImage})` }}
    >
      <div className="login-box-container">
        <div className="row">
          <div className="col-12 text-center memes">
            <img
              alt="memes_logo"
              src={WATERMARK_URL}
              className="img-fluid"
              style={{
                marginBottom: "30px",
                height: "75px",
                width: "75px",
                borderRadius: "7px",
              }}
            />
          </div>
        </div>
        <div className="login-box-inner">
          <div className="row">
            <div className="col-12">
              <h1
                className="login-heading"
                style={{
                  textAlign: "center",
                  marginBottom: "1rem",
                  fontSize: "16px",
                  textTransform: "none",
                  fontWeight: 600,
                }}
              >
                Service not reachable. We are working on it.
              </h1>
            </div>
            <div className="col-12 col-xl-12">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.location.reload()
                }}
                className="btn btn-primary login-btn w-100"
                style={{
                  fontWeight: 600,
                }}
              >
                Try to Reload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
