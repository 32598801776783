const types = {
  UPLOAD_MEDIA_REQUEST: "UPLOAD_MEDIA_REQUEST",
  UPLOAD_MEDIA_SUCCESS: "UPLOAD_MEDIA_SUCCESS",
  UPLOAD_MEDIA_ERROR: "UPLOAD_MEDIA_ERROR",

  GET_USER_MEDIA_REQUEST: "GET_USER_MEDIA_REQUEST",
  GET_USER_MEDIA_SUCCESS: "GET_USER_MEDIA_SUCCESS",
  GET_USER_MEDIA_ERROR: "GET_USER_MEDIA_ERROR",

  GET_USER_MEDIA_PAGINATION_REQUEST: "GET_USER_MEDIA_PAGINATION_REQUEST",
  GET_USER_MEDIA_PAGINATION_SUCCESS: "GET_USER_MEDIA_PAGINATION_SUCCESS",
  GET_USER_MEDIA_PAGINATION_ERROR: "GET_USER_MEDIA_PAGINATION_ERROR",

  DELETE_USER_MEDIA_REQUEST: "DELETE_USER_MEDIA_REQUEST",
  DELETE_USER_MEDIA_SUCCESS: "DELETE_USER_MEDIA_SUCCESS",
  DELETE_USER_MEDIA_ERROR: "DELETE_USER_MEDIA_ERROR",

  SAVE_USER_DESIGN_REQUEST: "SAVE_USER_DESIGN_REQUEST",
  SAVE_USER_DESIGN_SUCCESS: "SAVE_USER_DESIGN_SUCCESS",
  SAVE_USER_DESIGN_ERROR: "SAVE_USER_DESIGN_ERROR",

  SAVE_EXISTING_USER_DESIGN_REQUEST: "SAVE_EXISTING_USER_DESIGN_REQUEST",
  SAVE_EXISTING_USER_DESIGN_SUCCESS: "SAVE_EXISTING_USER_DESIGN_SUCCESS",
  SAVE_EXISTING_USER_DESIGN_ERROR: "SAVE_EXISTING_USER_DESIGN_ERROR",

  GET_USER_DESIGNS_REQUEST: "GET_USER_DESIGNS_REQUEST",
  GET_USER_DESIGNS_SUCCESS: "GET_USER_DESIGNS_SUCCESS",
  GET_USER_DESIGNS_ERROR: "GET_USER_DESIGNS_ERROR",

  GET_USER_DESIGNS_MORE_REQUEST: "GET_USER_DESIGNS_MORE_REQUEST",
  GET_USER_DESIGNS_MORE_SUCCESS: "GET_USER_DESIGNS_MORE_SUCCESS",
  GET_USER_DESIGNS_MORE_ERROR: "GET_USER_DESIGNS_MORE_ERROR",

  GET_USER_DESIGNS_ALL_REQUEST: "GET_USER_DESIGNS_ALL_REQUEST",
  GET_USER_DESIGNS_ALL_SUCCESS: "GET_USER_DESIGNS_ALL_SUCCESS",
  GET_USER_DESIGNS_ALL_ERROR: "GET_USER_DESIGNS_ALL_ERROR",

  GET_USER_DESIGNS_ALL_MORE_REQUEST: "GET_USER_DESIGNS_ALL_MORE_REQUEST",
  GET_USER_DESIGNS_ALL_MORE_SUCCESS: "GET_USER_DESIGNS_ALL_MORE_SUCCESS",
  GET_USER_DESIGNS_ALL_MORE_ERROR: "GET_USER_DESIGNS_ALL_MORE_ERROR",

  GET_SAVED_JSON_REQUEST: "GET_SAVED_JSON_REQUEST",
  GET_SAVED_JSON_SUCCESS: "GET_SAVED_JSON_SUCCESS",
  GET_SAVED_JSON_ERROR: "GET_SAVED_JSON_ERROR",

  DELETE_USER_DESIGN_REQUEST: "DELETE_USER_DESIGN_REQUEST",
  DELETE_USER_DESIGN_SUCCESS: "DELETE_USER_DESIGN_SUCCESS",
  DELETE_USER_DESIGN_ERROR: "DELETE_USER_DESIGN_ERROR",

  DOWNLOAD_MEME_REQUEST: "DOWNLOAD_MEME_REQUEST",
  DOWNLOAD_MEME_SUCCESS: "DOWNLOAD_MEME_SUCCESS",
  DOWNLOAD_MEME_ERROR: "DOWNLOAD_MEME_ERROR",

  GET_USER_FRIENDS_REQUEST: "GET_USER_FRIENDS_REQUEST",
  GET_USER_FRIENDS_SUCCESS: "GET_USER_FRIENDS_SUCCESS",
  GET_USER_FRIENDS_ERROR: "GET_USER_FRIENDS_ERROR",

  POST_MEME_REQUEST: "POST_MEME_REQUEST",
  POST_MEME_SUCCESS: "POST_MEME_SUCCESS",
  POST_MEME_ERROR: "POST_MEME_ERROR",

  GET_SAVED_JSON_REQUEST: "GET_SAVED_JSON_REQUEST",
  GET_SAVED_JSON_SUCCESS: "GET_SAVED_JSON_SUCCESS",
  GET_SAVED_JSON_ERROR: "GET_SAVED_JSON_ERROR",

  GET_MEDIA_FROM_LINK_REQUEST: "GET_MEDIA_FROM_LINK_REQUEST",
  GET_MEDIA_FROM_LINK_SUCCESS: "GET_MEDIA_FROM_LINK_SUCCESS",
  GET_MEDIA_FROM_LINK_ERROR: "GET_MEDIA_FROM_LINK_ERROR",

  UPDATE_MY_GUEST_DESIGNS_REQUEST: "UPDATE_MY_GUEST_DESIGNS_REQUEST",
  UPDATE_MY_GUEST_DESIGNS_SUCCESS: "UPDATE_MY_GUEST_DESIGNS_SUCCESS",
  UPDATE_MY_GUEST_DESIGNS_ERROR: "UPDATE_MY_GUEST_DESIGNS_ERROR",
};
export default types;
