import fetchService from './index'

export const getGifs = ({ url, isUrl }) => fetchService({
  method: 'GET',
  url,
  isUrl: true,
  myHeaders: {
    'Content-Type': 'application/json',
  },
})
