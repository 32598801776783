import React from "react";
import GPlayStoreImg from "images/smallScreen/g-play.png";
import AppStoreImg from "images/smallScreen/app-store.jpg";
import MemesLogoImg from "images/memes/memes-com.png";

const SmallScreen = () => {
  return (
    <div className="small-screen">
      <div className="logo_sm">
        <img
          src={MemesLogoImg}
          alt="logo"
          onClick={() =>
            window.location.assign(process.env.REACT_APP_MEMES_LOGIN_URL)
          }
        />
      </div>
      <div className="text-sm">
        Meme app is now available on all iOS &amp; Android devices
      </div>
      <div className="button-sm">
        <a href="https://apps.apple.com/in/app/meme-creator-make-funny-memes/id1175978897">
          <img src={AppStoreImg} alt="app store" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.memes.plus">
          <img src={GPlayStoreImg} alt="google play" />
        </a>
      </div>
    </div>
  );
};

export default SmallScreen;
