import React, { Suspense, lazy, Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import SmallScreen from "components/common/SmallScreen";
import {
  setCookieForTesting,
  updateCookie,
  getUserData,
  isFirefox,
  saveMemeGeneratorCookie,
  userIdPrompt,
  isGuestUserPrompt,
  getGuestDesignId,
  removeGuestDesgnId,
} from "shared/utilities";
import ReactNotification from "react-notifications-component";
import { checkBarrierValid, memeAuthenticate } from "services";
import FontFaceObserver from "fontfaceobserver";
import { PageLoader, ShowProgressLoader } from "components/common/loaders";
// import { fontFamilies_static } from "shared/constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserSubscriptionStatus,
  getStripeProducts,
  getUserSavedCards,
} from "redux/actions/payments";
import { updateMyGuestDesigns } from "redux/actions/profile";
import { GUEST_USER_ID, DUMMY_LOGIN_TOKEN } from "shared/constants";
import NotFound from "components/notFound";
// const NotFound = lazy(() => import("./components/notFound"));
// const AllFramesLayouts = lazy(() => import("./container/allFramesLayouts"));
// const FreestyleEditor = lazy(() => import("./container/freestyleEditor"));
// const FramesEditor = lazy(() => import("./container/framesEditor"));
const MemesEditor = lazy(() => import("./container/mergedEditor"));
// const AllSavedDesigns = lazy(() => import("./container/allSavedDesigns"));
// const AllFreestyleTemplates = lazy(() =>
//   import("./container/allTrendingTemplates")
// );
var _self;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isfontLoaded: false,
      isAuth: false,
      isSavingGuestDesigns: false,
      isError: false,
    };
    _self = this;
    var font = new FontFaceObserver("George Rounded");
    font
      .load()
      .then(function () {
        _self.setState({
          isfontLoaded: true,
        });
      })
      .catch((error) => {
        console.log("error in loading font APP.js", error);
        _self.setState({
          isfontLoaded: true,
        });
      });
  }

  async componentDidMount() {
    let {
      getUserSubscriptionStatus,
      getStripeProducts,
      updateMyGuestDesigns,
      getUserSavedCards,
    } = this.props;

    if (
      process.env.REACT_APP_URL === "dev" ||
      process.env.REACT_APP_URL === "staging"
    ) {
      // let id = 4036664;
      // let id = 206;
      let id = 3750368;
      // let id = 30303000000;
      if (isFirefox()) {
        setCookieForTesting({
          id: 4036664,
          isGuestUser: false,
        });
        getUserSubscriptionStatus().then((action) => {
          if (action.type === "GET_USER_SUBSCRIPTION_STATUS_SUCCESS") {
            this.setState({ isAuth: true });
            if (!action.payload.is_active) {
              getStripeProducts();
            }
          }
        });
      } else {
        // let isGuestUser = isGuestUserPrompt();
        let isGuestUser = false

        // if (!isGuestUser) {
        //   id = userIdPrompt();
        // }
        // id = userIdPrompt();
        setCookieForTesting({ id, isGuestUser });
        if (!isGuestUser && getGuestDesignId()) {
          // update this user's guest designs
          await updateMyGuestDesigns(getGuestDesignId()).then((action) => {
            if (action.type === "UPDATE_MY_GUEST_DESIGNS_SUCCESS") {
              removeGuestDesgnId();
            }
          });
        }
        if (!isGuestUser) {
          getUserSubscriptionStatus().then((action) => {
            console.log("@getUserSubscriptionStatus ===", action);
            if (action.type === "GET_USER_SUBSCRIPTION_STATUS_SUCCESS") {
              let { is_active, subscription_status, customer_id } =
                action.payload;
              this.setState({ isAuth: true });
              if (!is_active) {
                getStripeProducts();
              }
              if (!is_active && !subscription_status && customer_id !== 0) {
                getUserSavedCards();
              }
            } else {
              this.setState({
                isError: true,
              });
            }
          });
        } else {
          this.setState({ isAuth: true });
        }
      }
    } else {
      this.checkBarrierValidHandler();
    }
  }

  checkBarrierValidHandler = async () => {
    let { getUserSubscriptionStatus, getStripeProducts, updateMyGuestDesigns } =
      this.props;
    checkBarrierValid()
      .then(async (response) => {
        if (response.success === true) {
          saveMemeGeneratorCookie({
            data: { ...response.data, isGuestUser: false },
          });
          memeAuthenticate()
            .then(async (res) => {
              if (res.success) {
                updateCookie(res.data);
                getUserSubscriptionStatus().then((action) => {
                  console.log("@getUserSubscriptionStatus ===", action);
                  if (action.type === "GET_USER_SUBSCRIPTION_STATUS_SUCCESS") {
                    let { is_active, subscription_status } = action.payload;
                    this.setState({ isAuth: true });
                    if (!is_active) {
                      getStripeProducts();
                    }
                    if (!is_active && !subscription_status) {
                      getUserSavedCards();
                    }
                  } else {
                    this.setState({
                      isError: true,
                    });
                  }
                });
                if (getGuestDesignId()) {
                  // uddate this user's guest designs
                  await updateMyGuestDesigns(getGuestDesignId()).then(
                    (action) => {
                      console.log(
                        "updateMyGuestDesigns action =======",
                        action
                      );
                      if (action.type === "UPDATE_MY_GUEST_DESIGNS_SUCCESS") {
                        removeGuestDesgnId();
                      }
                    }
                  );
                }
              } else {
                // this.redirectToLogin();
                saveMemeGeneratorCookie({
                  data: {
                    isGuestUser: true,
                    id: GUEST_USER_ID,
                    login_token: DUMMY_LOGIN_TOKEN,
                  },
                });
                this.setState({ isAuth: true });
                if (getGuestDesignId()) {
                  // uddate this user's guest designs
                  await updateMyGuestDesigns(getGuestDesignId()).then(
                    (action) => {
                      console.log(
                        "updateMyGuestDesigns action =======",
                        action
                      );
                      if (action.type === "UPDATE_MY_GUEST_DESIGNS_SUCCESS") {
                        removeGuestDesgnId();
                      }
                    }
                  );
                }
              }
            })
            .catch((error) => {
              console.log("@memeAuthenticate error==", error);
              this.redirectToLogin();
            });
        } else {
          // this.redirectToLogin();
          saveMemeGeneratorCookie({
            data: {
              isGuestUser: true,
              id: GUEST_USER_ID,
              login_token: DUMMY_LOGIN_TOKEN,
            },
          });
          this.setState({ isAuth: true });
        }
      })
      .catch((error) => {
        console.log("@checkBarrierValid auth-check error=", error);
        this.redirectToLogin();
      });
  };

  redirectToLogin = () => {
    this.setState({ isAuth: false });
    window.location.assign(process.env.REACT_APP_MEMES_LOGIN_URL);
  };

  render() {
    const { isAuth, isfontLoaded, isSavingGuestDesigns, isError } = this.state;
    return isfontLoaded && isAuth && !isError ? (
      <>
        <SmallScreen />
        <ReactNotification />
        <Suspense fallback={<PageLoader />}>
          <div style={{ position: "relative" }}>
            {isSavingGuestDesigns ? (
              <ShowProgressLoader msg="Saving your progress" />
            ) : null}
            <Switch>
              <Route exact path="/create-memes" component={MemesEditor} />
              <Redirect from="/" to="/create-memes" />
              <Redirect from="*" to="/create-memes" />
            </Switch>
          </div>
        </Suspense>
      </>
    ) : isError ? (
      <NotFound />
    ) : (
      <PageLoader />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: getUserData(),
  };
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      getUserSubscriptionStatus,
      getStripeProducts,
      updateMyGuestDesigns,
      getUserSavedCards,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatch)(App);

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return getUserData().isGuestUser === false && getUserData().id ? (
        <Component {...props} />
      ) : (
        window.location.assign(process.env.REACT_APP_MEMES_LOGIN_URL)
      );
    }}
  />
);

// memes-generator.iapplabz.co.in
