const types = {
  GET_GIFS_REQUEST: "GET_GIFS_REQUEST",
  GET_GIFS_SUCCESS: "GET_GIFS_SUCCESS",
  GET_GIFS_ERROR: "GET_GIFS_ERROR",
  
  GET_GIFS_PAGINATION_REQUEST: "GET_GIFS_PAGINATION_REQUEST",
  GET_GIFS_PAGINATION_SUCCESS: "GET_GIFS_PAGINATION_SUCCESS",
  GET_GIFS_PAGINATION_ERROR: "GET_GIFS_PAGINATION_ERROR",
};
export default types;
