import React from "react";
import { PulseLoader } from "react-spinners";
import MemesLogoImg from "images/memes/memes-com.png";
import BarLoaderGif from "images/icons/loading-gif.gif";
import { WATERMARK_URL } from "shared/constants";
export const PageLoader = () => {
  return (
    <div className="page-loader">
      <img src={MemesLogoImg} alt="logo" />
      <PulseLoader size={14} color={"#ffffff"} loading={true} />
    </div>
  );
};

export const MemesPageLoader = ({ msg }) => {
  return (
    <div
      className="page-loader custom-loader"
      id="custom-loader"
      style={{
        zIndex: 9999,
      }}
    >
      <img src={MemesLogoImg} alt="logo" />
      <PulseLoader size={14} color={"#ffffff"} loading={true} />
      {msg ? (
        <div className="loader_text" style={{ fontSize: "18px" }}>
          {msg}
        </div>
      ) : null}
    </div>
  );
};

export const ShowProgressLoader = ({ msg, imgHeight, style }) => {
  return (
    <div
      className="custom-loader"
      id="custom-loader"
      style={
        style
          ? style
          : {
              zIndex: 9,
            }
      }
    >
      <div className="loading_inner">
        <div className="video-loader">
          <img
            src={BarLoaderGif}
            alt="loading.."
            style={imgHeight ? { height: imgHeight } : {}}
          />
        </div>
        <div className="loader_text">{msg}</div>
      </div>
    </div>
  );
};

export const ApiCallLoader = ({ msg, style }) => {
  return (
    <div
      className="custom-loader"
      id="custom-loader"
      style={
        style
          ? {
              ...style,
            }
          : {}
      }
    >
      <div className="loading_inner">
        <div className="video-loader">
          <img src={BarLoaderGif} alt="meme_loader" />
        </div>
        <div className="loader_text">
          {msg && msg !== "" ? msg : "Processing animation..."}
        </div>
      </div>
    </div>
  );
};

export const ImageLoader = ({ index, ...props }) => {
  return (
    <div
      {...props}
      className={index % 2 === 0 ? "blur-image-2" : "blur-image-1"}
    />
  );
};
