import { combineReducers } from "redux";
import gifs from "./gifs";
import stockImages from "./stockImages";
import profile from "./profile";
import bgImages from "./bgImages";
import payments from "./payments";

export default combineReducers({
  gifs,
  stockImages,
  profile,
  bgImages,
  payments,
});
