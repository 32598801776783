const types = {
  GET_USER_SUBSCRIPTION_STATUS_REQUEST: "GET_USER_SUBSCRIPTION_STATUS_REQUEST",
  GET_USER_SUBSCRIPTION_STATUS_SUCCESS: "GET_USER_SUBSCRIPTION_STATUS_SUCCESS",
  GET_USER_SUBSCRIPTION_STATUS_ERROR: "GET_USER_SUBSCRIPTION_STATUS_ERROR",

  GET_STRIPE_PRODUCTS_REQUEST: "GET_STRIPE_PRODUCTS_REQUEST",
  GET_STRIPE_PRODUCTS_SUCCESS: "GET_STRIPE_PRODUCTS_SUCCESS",
  GET_STRIPE_PRODUCTS_ERROR: "GET_STRIPE_PRODUCTS_ERROR",

  ADD_CARD_TO_STRIPE_REQUEST: "ADD_CARD_TO_STRIPE_REQUEST",
  ADD_CARD_TO_STRIPE_SUCCESS: "ADD_CARD_TO_STRIPE_SUCCESS",
  ADD_CARD_TO_STRIPE_ERROR: "ADD_CARD_TO_STRIPE_ERROR",

  RETRIEVE_USER_SUBSCRIPTION_REQUEST: "RETRIEVE_USER_SUBSCRIPTION_REQUEST",
  RETRIEVE_USER_SUBSCRIPTION_SUCCESS: "RETRIEVE_USER_SUBSCRIPTION_SUCCESS",
  RETRIEVE_USER_SUBSCRIPTION_ERROR: "RETRIEVE_USER_SUBSCRIPTION_ERROR",

  DELETE_USER_SUBSCRIPTION_REQUEST: "DELETE_USER_SUBSCRIPTION_REQUEST",
  DELETE_USER_SUBSCRIPTION_SUCCESS: "DELETE_USER_SUBSCRIPTION_SUCCESS",
  DELETE_USER_SUBSCRIPTION_ERROR: "DELETE_USER_SUBSCRIPTION_ERROR",

  GET_USER_SAVED_CARDS_REQUEST: "GET_USER_SAVED_CARDS_REQUEST",
  GET_USER_SAVED_CARDS_SUCCESS: "GET_USER_SAVED_CARDS_SUCCESS",
  GET_USER_SAVED_CARDS_ERROR: "GET_USER_SAVED_CARDS_ERROR",

  SET_USER_DEFAULT_CARD_REQUEST: "SET_USER_DEFAULT_CARD_REQUEST",
  SET_USER_DEFAULT_CARD_SUCCESS: "SET_USER_DEFAULT_CARD_SUCCESS",
  SET_USER_DEFAULT_CARD_ERROR: "SET_USER_DEFAULT_CARD_ERROR",

  REMOVE_USER_CARD_REQUEST: "REMOVE_USER_CARD_REQUEST",
  REMOVE_USER_CARD_SUCCESS: "REMOVE_USER_CARD_SUCCESS",
  REMOVE_USER_CARD_ERROR: "REMOVE_USER_CARD_ERROR",

  ADD_USER_CARD_REQUEST: "ADD_USER_CARD_REQUEST",
  ADD_USER_CARD_SUCCESS: "ADD_USER_CARD_SUCCESS",
  ADD_USER_CARD_ERROR: "ADD_USER_CARD_ERROR",
};
export default types;
