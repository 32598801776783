import types from "../types/gifs";
const initialState = {
  isGetGifs: false,
  gifs: [],
  gifsError: false,
  gifPage: 1,
  isGetGifsPagination: false,
  gifSearch: "",
  isMoreGifs: true,
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    //
    // FETCH GIFS
    //
    case types.GET_GIFS_REQUEST:
      return {
        ...state,
        isGetGifs: true,
        gifSearch: action.payload.search,
        gifPage: 1,
        isMoreGifs: true,
      };
    case types.GET_GIFS_SUCCESS:
      return {
        ...state,
        isGetGifs: false,
        gifs: action.payload.data,
      };

    case types.GET_GIFS_ERROR:
      return {
        ...state,
        isGetGifs: false,
        gifsError: true,
        isMoreGifs: true
      };
    //
    // FETCH GIFS PAGINATION
    //
    case types.GET_GIFS_PAGINATION_REQUEST:
      return {
        ...state,
        isGetGifsPagination: true,
      };
    case types.GET_GIFS_PAGINATION_SUCCESS:
      return {
        ...state,
        isGetGifsPagination: false,
        gifs: state.gifs.concat(action.payload.data),
        gifPage: state.gifPage + 1,
        isMoreGifs: action.payload.data.length ? true : false,
      };

    case types.GET_GIFS_PAGINATION_ERROR:
      return {
        ...state,
        isGetGifsPagination: false,
        gifsError: true,
      };
    default:
      return state;
  }
};
