export const LIVE_URL_ =
  process.env.REACT_APP_URL === "dev"
    ? process.env.REACT_APP_ENDPOINT_DEV
    : process.env.REACT_APP_URL === "staging"
    ? process.env.REACT_APP_ENDPOINT_STAGING
    : process.env.REACT_APP_ENDPOINT_PRODUCTION;

export const LIVE_URL_HOME =
  process.env.REACT_APP_URL === "dev"
    ? `${process.env.REACT_APP_ENDPOINT_DEV}home`
    : process.env.REACT_APP_URL === "staging"
    ? `${process.env.REACT_APP_ENDPOINT_STAGING}home`
    : `${process.env.REACT_APP_ENDPOINT_PRODUCTION}home`;

export const API_ENDPOINT =
  process.env.REACT_APP_URL === "dev"
    ? `${process.env.REACT_APP_ENDPOINT_DEV}memesdevapi/`
    : process.env.REACT_APP_URL === "staging"
    ? `${process.env.REACT_APP_ENDPOINT_STAGING}memesdevapi/`
    : `${process.env.REACT_APP_ENDPOINT_PRODUCTION}memesdevapi/`;

// export const API_ENDPOINT =
//   process.env.REACT_APP_URL === "dev"
//     ? `${process.env.REACT_APP_ENDPOINT_PRODUCTION}memesdevapi/api`
//     : process.env.REACT_APP_URL === "staging"
//     ? `${process.env.REACT_APP_ENDPOINT_STAGING}memesdevapi/api`
//     : `${process.env.REACT_APP_ENDPOINT_PRODUCTION}memesdevapi/api`;

export const PLACEHOLDER_IMAGES =
  process.env.REACT_APP_URL === "dev"
    ? `${process.env.REACT_APP_ENDPOINT_DEV}memesdevapi/frames_placeholder/`
    : process.env.REACT_APP_URL === "staging"
    ? `${process.env.REACT_APP_ENDPOINT_STAGING}memesdevapi/frames_placeholder/`
    : `${process.env.REACT_APP_ENDPOINT_PRODUCTION}memesdevapi/frames_placeholder/`;

export const NEW_PLACEHOLDER_IMAGES =
  process.env.REACT_APP_URL === "dev"
    ? `${process.env.REACT_APP_ENDPOINT_DEV}memesdevapi/placeholders/`
    : process.env.REACT_APP_URL === "staging"
    ? `${process.env.REACT_APP_ENDPOINT_STAGING}memesdevapi/placeholders/`
    : `${process.env.REACT_APP_ENDPOINT_PRODUCTION}memesdevapi/placeholders/`;

export const MEMES_COOKIE =
  process.env.REACT_APP_URL === "dev"
    ? "localhost"
    : process.env.REACT_APP_URL === "staging"
    ? "iapplabz.co.in"
    : "memes.com";
export const APP_MEMES_API_ENDPOINT = "https://app.memes.com/";
export const APP_MEMES_AUTH_ENDPOINT = "https://api.memes.com/";
export const WATERMARK_URL =
  process.env.REACT_APP_URL === "dev"
    ? `${process.env.REACT_APP_ENDPOINT_DEV}memesdevapi/watermark/Icon.png`
    : process.env.REACT_APP_URL === "staging"
    ? `${process.env.REACT_APP_ENDPOINT_STAGING}memesdevapi/watermark/Icon.png`
    : `${process.env.REACT_APP_ENDPOINT_PRODUCTION}memesdevapi/watermark/Icon.png`;

export const GOOGLE_DRIVE_CREDS =
  process.env.REACT_APP_URL === "dev" || process.env.REACT_APP_URL === "staging"
    ? {
        DEVELOPER_KEY: "AIzaSyAM2d5Mel5Vi_AjABD_W3pMlegMk_rkcNQ",
        APP_ID: "782236266409",
        CLIENT_ID:
          "782236266409-fvkmvjm5q9mqn9hsq6uom08b3b1c10ea.apps.googleusercontent.com",
        // "782236266409-36c1fdusnckij3fmtg2pq5c7kd5h45qa.apps.googleusercontent.com",
        SCOPES: [
          "https://www.googleapis.com/auth/drive.readonly",
          "https://www.googleapis.com/auth/drive.file",
        ],
      }
    : {
        DEVELOPER_KEY: "AIzaSyCZtR7qfkQkVrzTxC0rlMbm5VnYRfP3pKA",
        APP_ID: "376367998575",
        CLIENT_ID:
          "376367998575-cekb5genrmo1hsp1n235rkvv99p2lk6k.apps.googleusercontent.com",
        SCOPES: [
          "https://www.googleapis.com/auth/drive.readonly",
          "https://www.googleapis.com/auth/drive.file",
        ],
      };
export const MEME_API_DEFAULTS = {
  user_id: "56018721605162195",
  loginToken: "Y7EZiL79a0FoSCGIQuWUBGBU",
  device_token: "",
  is_public: 1,
};

export const STRIPE_SECRET =
  process.env.REACT_APP_URL === "dev" || process.env.REACT_APP_URL === "staging"
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE;

export const GOOGLE_ADS_ID = "977-158-2354";
export const ALLOWED_IMAGE_TYPES = ["png", "jpg", "jpeg", "gif"];
export const ALLOWED_VIDEO_TYPES = [
  "mkv",
  "mov",
  "mp4",
  "mpeg",
  "mpg",
  "wmv",
  "f4v",
  "flv",
  "webm",
  "avi",
];
export const GUEST_USER_ID = "0";
export const DUMMY_LOGIN_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";
export const IMAGE_OPTIONS = ["photos", "gifs", "uploads"];
export const MAX_ZOOM = 1000;

export const fontSizes_static = [
  { value: 6, label: 6 },
  { value: 8, label: 8 },
  { value: 10, label: 10 },
  { value: 12, label: 12 },
  { value: 14, label: 14 },
  { value: 18, label: 18 },
  { value: 20, label: 20 },
  { value: 22, label: 22 },
  { value: 24, label: 24 },
  { value: 26, label: 26 },
  { value: 28, label: 28 },
  { value: 30, label: 30 },
  { value: 32, label: 32 },
  { value: 34, label: 34 },
  { value: 38, label: 38 },
  { value: 42, label: 42 },
  { value: 48, label: 48 },
  { value: 60, label: 60 },
  { value: 64, label: 64 },
  { value: 68, label: 68 },
  { value: 72, label: 72 },
  { value: 76, label: 76 },
  { value: 80, label: 80 },
];

export const fontFamilies_static = [
  { value: "Arial", label: "Arial" },
  // { value: "Times New Roman", label: "Times New Roman" },
  { value: "Hanalei", label: "Hanalei" },
  { value: "Acme", label: "Acme" },
  { value: "Monoton", label: "Monoton" },
  { value: "Tangerine", label: "Tangerine" },
  { value: "Rock Salt", label: "Rock Salt" },
  { value: "Audiowide", label: "Audiowide" },
  { value: "Press Start 2P", label: "Press Start 2P" },
  { value: "Caesar Dressing", label: "Caesar Dressing" },
  { value: "Flavors", label: "Flavors" },
  { value: "Princess Sofia", label: "Princess Sofia" },
  { value: "Harmattan", label: "Harmattan" },
  { value: "Nobile", label: "Nobile" },
  { value: "Syne Mono", label: "Syne Mono" },
  { value: "Turret Road", label: "Turret Road" },
  { value: "Caveat", label: "Caveat" },
  { value: "Comic Neue", label: "Comic Neue" },
  { value: "Concert One", label: "Concert One" },
  { value: "Dancing Script", label: "Dancing Script" },
  { value: "Engagement", label: "Engagement" },
  { value: "Grenze Gotisch", label: "Grenze Gotisch" },
  { value: "Indie Flower", label: "Indie Flower" },
  { value: "Lobster", label: "Lobster" },
  { value: "Modak", label: "Modak" },
  { value: "Raleway", label: "Raleway" },
  { value: "Source Sans Pro", label: "Source Sans Pro" },
  { value: "Ultra", label: "Ultra" },
];
